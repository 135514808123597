import { useMemo, memo, useCallback } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  useTranslations,
  Table,
  useTable,
  Button,
  Divider,
  CSVExportIcon,
  Grid,
  buildAndDownloadCSV,
} from '@uniqkey-frontend/shared-app';
import { CheckResponseModel } from '@uniqkey-backend-organization-web/api-client';
import { noop } from 'lodash';

interface IViewEmployeesModalProps {
  handleToggle: () => void;
  isModalOpen: boolean;
  employees: CheckResponseModel[];
}

const CSV_NAME = 'Not_migrated_employees';
const CSV_CONFIG = { columns: ['email', 'name'] };

const ViewEmployeesModal = (props: IViewEmployeesModalProps) => {
  const { handleToggle, isModalOpen, employees } = props;
  const { t } = useTranslations();
  const { createColumns } = useTable<CheckResponseModel>();

  const columns = useMemo(() => createColumns([
    {
      title: t('migration.viewEmployeesModal.email'),
      field: 'email',
      render: (rowData) => (
        <Typography variant="body2">{rowData.email}</Typography>
      ),
      width: '50%',
      sorting: false,
    },
    {
      title: t('migration.viewEmployeesModal.name'),
      field: 'name',
      render: (rowData) => (
        <Typography variant="body2">{rowData.name}</Typography>
      ),
      width: '50%',
      sorting: false,
    },
  ]), [createColumns, t]);

  const employeesCount = useMemo(() => employees.length, [employees]);

  const handleExportToCsvClick = useCallback(() => {
    buildAndDownloadCSV({
      exportData: employees, name: CSV_NAME, config: CSV_CONFIG,
    });
  }, [employees]);

  return (
    <Dialog onClose={handleToggle} open={isModalOpen} maxWidth="xl">
      <DialogTitle onClose={handleToggle}>
        {t('migration.viewEmployeesModal.title', { count: employeesCount })}
      </DialogTitle>
      <Table
        t={t}
        data={employees}
        activePage={1}
        columns={columns}
        onActivePageChange={noop}
        onPerPageChange={noop}
        perPage={employeesCount}
        totalItems={employeesCount}
        rowHeight={51}
        hidePagination
        hasBottomShadow={false}
      />
      <Divider />
      <Grid container justifyContent="center" p={2}>
        <Grid item xs={3}>
          <Button fullWidth icon={<CSVExportIcon />} onClick={handleExportToCsvClick}>
            {t('migration.viewEmployeesModal.exportToCsv')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default memo(ViewEmployeesModal);
